import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
const BuyNowQuantityChange = ({ handleQuantity, orderQty, availableForSale }) => {

    const isQtyAvailable = Number(availableForSale) ? true : false
    return (
        <div data-tooltip-id="my-tooltip" data-tooltip-content="Quantity not available" className='flex rounded-full bg-[#F3F3F3] text-sm md:text-base'>
            <button
                disabled={!isQtyAvailable}
                onClick={() => handleQuantity('remove')}
                className='w-8  text-xl text-[#A3A3A3] font-bold '>-</button>
            <button
                disabled={!isQtyAvailable}
                className='w-8 md:w-10  text-xl text-[#3A4980] font-bold'>
                {orderQty}
            </button>
            <button
                disabled={!isQtyAvailable}
                onClick={() => handleQuantity('add')}
                className='w-8  text-xl text-[#3A4980] font-bold'>
                +
            </button>
            {!isQtyAvailable && <Tooltip id="my-tooltip" />}
        </div>
    )
}

export default BuyNowQuantityChange