import React, { useRef, useState } from 'react';

const ProductGallery = ({ productImages }) => {
    const containerRef = useRef(null);
    const contentRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsDragging(true);
        setStartX(e.clientX);
        setScrollLeft(containerRef.current.scrollLeft);
        containerRef.current.style.scrollBehavior = 'auto';
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        containerRef.current.style.scrollBehavior = 'smooth';
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const deltaX = e.clientX - startX;
        containerRef.current.scrollLeft = scrollLeft - deltaX;
    };

    return (
        <div
            className='block md:sticky md:top-0 shrink-0 w-full md:w-[60%]  overflow-x-hidden '
        >
            <div
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                ref={containerRef}
                className='w-full min-h-fit h-full flex flex-row gap-5 overflow-x-auto whitespace-no-wrap relative no-scrollbar rounded-xl '>
                {productImages &&
                    Object.keys(productImages).map((key) => {
                        if (key === '_id') {
                            // Skip the _id property
                            return null;
                        }
                        return (
                            <div ref={contentRef} className='flex-shrink-0 w-96 items-center overflow-hidden cursor-pointer' key={key}>
                                <img
                                    loading='lazy'
                                    src={productImages[key]}
                                    alt={`Image ${key}`}
                                    className='w-full h-full rounded-xl object-cover'
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default ProductGallery;
