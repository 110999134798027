import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ProductGallery from "./ProductGallery";
import AddToWishList from "../wishlist/AddToWishList";
import BuyNow from "../product/BuyNow";
import Loader from "../loading-spinner";
import BASE_URL from "../constants";
import BuyNowQuantityChange from "./BuyNowQuantityChange";
import ExpectedDelivery from "../product/ExpectedDelivery";
const QuickViewModal = ({
  onClose,
  productId,
  variationId,
  parentName,
  subName,
  childName,
  availableForSale,
}) => {

  const [orderQty, setOrderQty] = useState(1);
  const [slug, setSlug] = useState("");
  const [isLoading, setIloading] = useState(false);
  const [id, setId] = useState(productId);
  const [productImages, setProductImages] = useState();
  const [singleProductData, setSingleProductData] = useState();
  const [variationData, setVariationsData] = useState();
  const [variationValue1, setVariationValue1] = useState();
  const [variationValue2, setVariationValue2] = useState();
  const [variation1, setVariation1] = useState();
  const [variation2, setVariation2] = useState();

  // Calculate the discount percentage for a product
  const discount = Math.round(
    ((singleProductData?.max_retail_price - singleProductData?.list_price) /
      singleProductData?.max_retail_price) *
    100
  );

  const availableQuantity = singleProductData?.inventory?.qty_available;
  const fetchData = async (apiUrl) => {
    const res = await fetch(apiUrl);
    if (res.ok) {
      const data = res.json();
      return data;
    } else {
      console.log("something went wrong ", res.status);
    }
  };
  useEffect(() => {
    const apiUrl = `${BASE_URL}/v1/products/get-product/${id}`;
    fetchData(apiUrl).then((data) => {
      setSingleProductData(data[0]);
      setProductImages(data[0].product_images);
      setVariationValue1(data[0]?.variation_value1);
      setVariationValue2(data[0]?.variation_value2);
      setSlug(data[0].item_slug)
    });
  }, [id]);

  useEffect(() => {
    setIloading(true);
    const apiUrl = `${BASE_URL}/v1/variations/get-product-variations?filter[variation_group_id][$eq]=${variationId}`;

    fetchData(apiUrl).then((data) => {
      setVariationsData(data?.response);
      setVariation1(data?.response?.variation_value1);
      setVariation2(data?.response?.variation_value2);
      setIloading(false);
    });
  }, [variationId]);


  const handleVariation = useCallback((productId) => {
    setId(productId);
  }, []);



  const selectVariation = async (value1, value2, flag1, flag2) => {
    setVariationValue1(value1);
    setVariationValue2(value2);
    const requestBody = {
      variation_value1: value1,
      variation_value2: value2,
      variation_group_id: singleProductData?.variation_group_id,
      variation1: flag1,
      variation2: flag2,
    };

    try {
      const response = await fetch(`${BASE_URL}/v1/variations/get-product`, {
        method: "POST",

        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json", // Set content type to JSON

        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data) {
          setSingleProductData(data?.product_detail[0]);
          setId(data?.product_detail[0]?._id);
          setData(data.product_detail);

          if (flag1) {
            setVariation2(data.variation_result[0].variation_value2);
          }
          if (flag2) {
            setVariation1(data.variation_result[0].variation_value1);
          }
        } else {
          toast.error("Not available");
        }
      }

      // Handle the response here
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleShare = (platform) => {
    toggleDropdown(); // Close the dropdown after selection
    const shareUrl = `https://www.21genx.com/product/${slug}/buy/${id}`; // Replace with your actual URL

    let shareLink = "https://www.21genx.com";

    switch (platform.toLowerCase()) {
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?text=Check out this link&url=${encodeURIComponent(
          shareUrl
        )}`;
        break;

      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;

      case "copy":
        // Copy to clipboard logic
        copyToClipboard(shareUrl);
        toast.success("Link copied successfully");
        return;

      default:
        // Handle unknown platform or provide a default behavior
        console.error("Unknown platform:", platform);
        return;
    }

    window.open(shareLink, "_blank");
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Copy to clipboard failed:", err);
    }

    document.body.removeChild(textArea);
  };
  //review rating details
  const [ratingDetails, setRatingDetails] = useState();
  const getReviewRatings = async () => {
    const apiUrl = BASE_URL + `/v1/review/avarage/${id}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    setRatingDetails(data);
  };

  useEffect(() => {
    getReviewRatings();
  }, []);


  const handleQuantity = useCallback(
    (type) => {
      if (type === "add") {
        setOrderQty((prevValue) => {
          if (prevValue < availableQuantity) {
            return prevValue + 1;
          } else {
            toast.error("Quantity Not Available");
            return prevValue;
          }
        });
      }
      if (type === "remove") {
        setOrderQty((prevValue) => {
          if (prevValue === 1) {
            return 1;
          } else {
            return prevValue - 1;
          }
        });
      }
    },
    [availableQuantity]
  );

  const handleClose = (e) => {
    if (e.target.id === "container") {
      onClose();
    }
  };
  let productdescription = singleProductData?.bullet_points?.slice(0, 1);
  if (isLoading) {
    return (
      <div className="fixed inset-0 z-50 backdrop-blur-sm flex justify-center items-center">
        <div className="h-96 flex justify-center items-center">
          <Loader className="w-16 h-16" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div
          // id="container"
          // onClick={(e) => handleClose(e)}
          className="max-w-screen-2xl mx-auto fixed inset-0 z-50 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-center"
        >


          <div className="w-full">
            <div className="h-0 w-11/12 lg:w-4/5 relative mx-auto">
              <button onClick={() => onClose()} className="absolute z-50 -top-7 -right-7 p-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>

              </button>
            </div>
            <div className="relative h-[36rem] w-11/12 lg:w-4/5  flex flex-col md:flex-row gap-5 mx-auto  bg-white quick-view-modal rounded-lg p-5 overflow-y-scroll">
              {productImages && <ProductGallery productImages={productImages} />}
              <div className="w-full md:flex-1 md:shrink-0 flex flex-col p-4 md:p-0 mt-4 lg:p-4  gap-3 md:overflow-y-scroll md:no-scrollbar">
                <p className="inline-block text-xs text-[#A3A9C2]">
                  <span>Home</span> {`>`} <span>{parentName}</span> {`>`}{" "}
                  <span>{subName}</span> {`>`}{" "}
                  <span className="text-[#3A4980] font-semibold">
                    {childName}
                  </span>
                </p>
                <div className="">
                  <a
                    target="_blank"
                    href={`/brand/${singleProductData?.brand?.brand_name?.toLowerCase()?.replace(' ', '-')}/home?brand_id=${singleProductData?.brand?._id}`}
                    className=" text-xs text-[#3A4980] font-semibold hover:underline hover:font-bold"
                  >
                    Visit <span> {singleProductData?.brand?.brand_name}</span>{" "}
                    store
                  </a>
                </div>

                <div className="flex  justify-between ">
                  <div>
                    <h1 className="text-xl 2xl:text-2xl font-semibold">
                      {singleProductData?.item_name}
                    </h1>
                    <p className="text-sm text-gray-500">
                      {productdescription}
                    </p>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div
                      onClick={() => toggleDropdown()}
                      className="w-fit h-fit p-2 rounded-lg bg-[#EDF0F8] cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M5.8199 8.49169C5.64516 8.17727 5.37096 7.92977 5.04034 7.78805C4.70972 7.64633 4.34139 7.6184 3.99318 7.70866C3.64498 7.79892 3.33659 8.00225 3.11645 8.28674C2.8963 8.57122 2.77686 8.92075 2.77686 9.28047C2.77686 9.64019 2.8963 9.98972 3.11645 10.2742C3.33659 10.5587 3.64498 10.762 3.99318 10.8523C4.34139 10.9425 4.70972 10.9146 5.04034 10.7729C5.37096 10.6312 5.64516 10.3837 5.8199 10.0693M5.8199 8.49169C5.9498 8.72551 6.02414 8.99397 6.02414 9.28047C6.02414 9.56697 5.9498 9.83616 5.8199 10.0693M5.8199 8.49169L12.7234 4.65674M5.8199 10.0693L12.7234 13.9042M12.7234 4.65674C12.8246 4.84736 12.9628 5.01589 13.1299 5.15248C13.297 5.28906 13.4897 5.39096 13.6966 5.45222C13.9036 5.51348 14.1207 5.53286 14.3352 5.50924C14.5497 5.48562 14.7574 5.41947 14.9461 5.31466C15.1347 5.20984 15.3006 5.06847 15.434 4.8988C15.5674 4.72913 15.6656 4.53457 15.7229 4.32649C15.7803 4.11842 15.7955 3.901 15.7678 3.68696C15.7402 3.47292 15.6701 3.26654 15.5617 3.07989C15.3481 2.71209 14.9988 2.44276 14.5888 2.3298C14.1787 2.21684 13.7407 2.26928 13.3689 2.47583C12.9971 2.68238 12.7212 3.02655 12.6005 3.43437C12.4798 3.8422 12.5239 4.2811 12.7234 4.65674ZM12.7234 13.9042C12.6198 14.0907 12.554 14.2957 12.5296 14.5076C12.5053 14.7195 12.5229 14.9341 12.5815 15.1392C12.6401 15.3443 12.7385 15.5359 12.8712 15.7029C13.0038 15.87 13.168 16.0093 13.3545 16.1129C13.5409 16.2165 13.746 16.2823 13.9579 16.3066C14.1698 16.331 14.3844 16.3134 14.5895 16.2547C14.7946 16.1961 14.9862 16.0977 15.1532 15.9651C15.3203 15.8325 15.4596 15.6682 15.5632 15.4818C15.7723 15.1052 15.8234 14.6609 15.705 14.2467C15.5867 13.8325 15.3086 13.4823 14.9321 13.2731C14.5555 13.0639 14.1112 13.0129 13.697 13.1312C13.2828 13.2496 12.9326 13.5276 12.7234 13.9042Z"
                          stroke="#3A4980"
                          stroke-width="1.299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown-content absolute bg-white shadow-md rounded mt-32">
                        <button
                          onClick={() => handleShare("twitter")}
                          className="block w-full py-2 px-4 text-left hover:bg-gray-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleShare("facebook")}
                          className="block w-full py-2 px-4 text-left hover:bg-gray-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleShare("copy")}
                          className="block w-full py-2 px-4 text-left hover:bg-gray-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="14"
                            viewBox="0 0 448 512"
                          >
                            <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between py-2 border-y items-center">
                  <div className="flex space-x-2 items-center">
                    {singleProductData?.inventory?.discount_percent ? (
                      <p className="text-xl text-[#B9BBBF] line-through">
                        ₹ {singleProductData?.inventory?.max_retail_price}
                      </p>
                    ) : null}
                    <p className="text-xl md:text-2xl text-[#3A4980] font-bold flex items-center">
                      ₹ {singleProductData?.inventory?.list_price}
                      {singleProductData?.inventory?.discount_percent ? (
                        <span className="ml-2 text-sm md:text-xl text-green-500 font-bold">
                          {singleProductData?.inventory?.discount_percent}% <span className="">Off</span>
                        </span>
                      ) : null}
                    </p>
                  </div>

                  <div className="flex flex-col space-y-2 w-fit h-fit">
                    {ratingDetails?.average_rating != 0 ? (
                      <div className="flex justify-end items-end space-x-1 md:space-x-2">
                        <div className="flex gap-x-1 bg-[#FBF3EA] p-2 rounded-3xl">
                          <img src={`/assets/image/star.svg`} />
                          <p className="text-xs text-[#D48D3B] font-semibold">
                            {ratingDetails?.average_rating}
                          </p>
                        </div>

                        <div className="flex gap-x-1 bg-[#FBF3EA] p-2 rounded-3xl">
                          <img src={`/assets/image/message.svg`} />
                          <p className="text-xs text-[#3A4980] font-semibold">
                            {ratingDetails?.total_count}
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* // variation section */}

                <div className="flex flex-col  ">
                  {singleProductData?.has_variations ? (
                    <p className="text-[#A3A9C2] text-sm capitalize py-2">
                      <span className="">
                        {singleProductData?.variation_name1?.replace(/_/g, " ")}{" "}
                      </span>
                      :
                      <span className="ml-2 text-sm text-[#3A4980] font-semibold">
                        {singleProductData?.variation_value1?.replace(
                          /_/g,
                          " "
                        )}
                      </span>
                    </p>
                  ) : null}
                  <div className="flex flex-row gap-2 flex-wrap">
                    {variationData?.variation_value1?.length > 0 && (
                      <div className="flex flex-row gap-2 flex-wrap">
                        {variation1.map((value, index) => (
                          <div className="" key={index}>
                            {variationData.variation_name1 !== "color" ? (
                              <div
                                className={`${variationValue1 === value.name
                                  ? "border-[#686363]"
                                  : "border-[#E9E5E5]"
                                  } w-auto px-4 py-2 rounded-md border border-solid overflow-hidden cursor-pointer`}
                                onClick={() =>
                                  selectVariation(
                                    value.name,
                                    variationValue2,
                                    true,
                                    false
                                  )
                                }
                              >
                                <p>{value?.name}</p>
                              </div>
                            ) : (
                              <div
                                className={`${variationValue1 === value.name
                                  ? "border-[#686363]"
                                  : "border-[#E9E5E5]"
                                  }  w-16 h-16 rounded-md border border-solid  overflow-hidden cursor-pointer`}
                                onClick={() =>
                                  selectVariation(
                                    value.name,
                                    variationValue2,
                                    true,
                                    false
                                  )
                                }
                              >
                                <img
                                  className="w-full h-full object-cover"
                                  src={value?.main_img}
                                  alt={`Product Image - ${value?.name}`}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    {variationData?.variation_value2?.length > 0 && (
                      <div className="">
                        {singleProductData?.variation_name2 && (
                          <p className="text-[#A3A9C2] text-sm capitalize py-2">
                            <span className="  ">
                              {singleProductData?.variation_name2.replace(
                                /_/g,
                                " "
                              )}{" "}
                              :
                            </span>

                            <span className="ml-2 text-sm text-[#3A4980] font-semibold">
                              {singleProductData?.variation_value2?.replace(
                                /_/g,
                                " "
                              )}
                            </span>
                          </p>
                        )}
                      </div>
                    )}
                    <div className="flex flex-row gap-2 flex-wrap">
                      {variationData?.variation_value2?.length > 0 ? (
                        <div className="flex flex-row gap-2 flex-wrap">
                          {variation2.map((value, index) => (
                            <div
                              className={``}
                              key={index}
                              onClick={() =>
                                selectVariation(
                                  variationValue1,
                                  value.name,
                                  false,
                                  true
                                )
                              }
                            >
                              {variationData.variation_name2 !== "color" ? (
                                <div
                                  className={`${variationValue2 === value.name
                                    ? "border-[#686363]"
                                    : "border-[#E9E5E5]"
                                    } w-auto px-4 py-2 rounded-md border border-solid overflow-hidden cursor-pointer`}
                                >
                                  <p>{value?.name}</p>
                                </div>
                              ) : (
                                <div
                                  className={`${variationValue2 === value.name
                                    ? "border-[#686363]"
                                    : "border-[#E9E5E5]"
                                    } w-16 h-16 rounded-md border border-solid overflow-hidden`}
                                  onClick={() =>
                                    selectVariation(
                                      variationValue1,
                                      value.name,
                                      false,
                                      true
                                    )
                                  }
                                >
                                  <img
                                    className="w-full h-full object-cover"
                                    src={value?.main_img}
                                    alt={`Product Image - ${value?.name}`}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>


                <div className="flex gap-5 ">
                  <BuyNowQuantityChange
                    orderQty={orderQty}
                    handleQuantity={handleQuantity}
                    availableForSale={availableForSale}
                  />
                  <BuyNow
                    orderQty={orderQty}
                    productId={productId}
                    availableForSale={availableForSale}
                  />
                </div>
                {/* Shipping feature */}
                <div className="flex flex-col rounded-xl border border-solid border-[#E4E4E4] p-3">
                  <div className="flex flex-row items-center space-x-3 pb-2 border-b border-solid border-[#E4E4E4]">
                    <div className="">
                      <img src={`/assets/image/delivery.svg`} />
                    </div>
                    <ExpectedDelivery productId={singleProductData?._id} sellerId={singleProductData?.seller_id} />
                  </div>
                  <div className="flex flex-row space-x-3 pt-2">
                    <div>
                      <img src={`/assets/image/return.svg`} />
                    </div>
                    <div className="flex flex-row items-center space-x-5">
                      <h1 className="text-sm text-[#1D364D] font-bold">
                        Return Delivery
                      </h1>
                      <p className="text-xs text-[#726C6C] underline">
                        Free 7 days Delivery Return. Details
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
      </>
    );
  }
};

export default QuickViewModal;
